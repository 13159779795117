import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import { Layout, PageCard } from '../components/common'
import { MetaData } from '../components/common/meta'



/**
* Main index page (home page)
*
* Loads all posts from Ghost and uses pagination to navigate through them.
* The number of posts that should appear per page can be setup
* in /utils/siteConfig.js under `postsPerPage`.
*
*/
const Index = ({ data, location, pageContext }) => {
    const posts = data.posts.edges
    const page = data.page.edges[0].node

    return (
        <>
            <MetaData location={location} />
            <Layout isHome={true}>
                <div className="container tag-line">
                    <em>Promoting Inclusive Economic Development and Community Prosperity</em>
                </div>
                <section className="about-us">
                    <div className="container">
                        <div dangerouslySetInnerHTML={{ __html: page.html }}></div>
                    </div>
                </section>
                <div className="container">
                    <h2 className="about-us-title">Our Services</h2>
                    <section className="page-feed pdb-xl">
                        {posts.map(({ node }) => (
                            // The tag below includes the markup for each post - components/common/PostCard.js
                            <PageCard key={node.id} page={node} />
                        ))}
                    </section>
                </div>
            </Layout>
        </>
    )
}

Index.propTypes = {
    data: PropTypes.shape({
        allGhostPage: PropTypes.object.isRequired,
    }).isRequired,
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
    }).isRequired,
    pageContext: PropTypes.object,
}

export default Index

// This page query loads all posts sorted descending by published date
// The `limit` and `skip` values are used for pagination
export const pageQuery = graphql`
  query GhostPostQuery {
    posts: allGhostPost(sort: {order: ASC, fields: published_at}, filter: {tags: {elemMatch: {slug: {eq: "our-services"}}}}) {
        edges {
            node {
            id
            feature_image
            excerpt
            title
            slug
            }
        }
    }   
    page: allGhostPage(filter: {slug: {eq: "home"}}) {
        edges {
            node {
                id
                html
                }
            }
        }
  }
`
